<template>
    <v-main>
        <v-container fluid>
            <v-row justify="center" class="mt-4">
                <v-col>
                    <span class="text-h5 font-weight-bold" v-text="'Certificates'"></span>
                </v-col>
            </v-row>

            <v-row>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-card-text>
                            <v-row>
                                <v-col md="4">
                                    <v-text-field v-model="search_keyword" label="Search ... "
                                        prepend-inner-icon="mdi-magnify" @keydown.enter="getData"
                                        @change="page = 1"></v-text-field>
                                </v-col>
                                <v-col md="3">
                                    <v-pagination v-model="page" circle :length="paginationLength">
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
            <v-row v-else>
                <v-col md="12">
                    <v-card elevation="6">
                        <v-card-title v-if="certificates.length != 0">
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Sr No</th>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">email</th>
                                        <th class="text-left">Course</th>
                                        <th class="text-left">Subject</th>
                                        <th class="text-left">Certificate No.</th>
                                        <th class="text-left">Certificate</th>
                                    </tr>
                                </thead>
                                <tbody v-if="certificates.length != 0">
                                    <tr v-for="(Certificate, i) in certificates" :key="`Certificate${i}`">
                                        <td style="min-width: 85px">

                                            {{ i + 1 }}
                                        </td>
                                        <td>{{ Certificate.candidate != null ? Certificate.candidate.name : "" }}</td>
                                        <td>{{ Certificate.candidate != null ? Certificate.candidate.email : "" }}</td>
                                        <td>{{ Certificate.course != null ? Certificate.course.name : "" }}</td>
                                        <td>{{ Certificate.course.subject != null ?
                        Certificate.course.subject.description : "" }}</td>
                                        <td>{{ Certificate.cert_no || "" }}</td>
                                        <td>
                                            <a target="_blank"
                                                :href="`${baseUrl}/api/course_exams?certificate_no=${encodeURIComponent(Certificate.cert_no)}`"
                                                style="padding: 7px;
    background-color: #1976d2;
    color: white;
    text-decoration: none;
    border-radius: 7px;">
                                                Certificate
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="12" class="text-center">No data found</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import axios from "axios";
export default {
    name: "CertificatePage",
    data() {
        return {
            form: {

            },
            page: 1,
            serialNoStarting: 1,
            paginationLength: 0,
            count: 0,
            search_keyword: "",
            certificates: [],
            isLoading: false

        };
    },
    watch: {
        page: "getData",
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            console.log(this.form.supervisor_id);
            this.isLoading = true;
            this.search_keyword = this.search_keyword ? this.search_keyword : "";
            console.log(this.search_keyword);
            let certificates = [];
            let query =
                "page=" +
                this.page +
                "&rowsPerPage=" +
                this.rowsPerPage +
                "&is_completed=true" +
                "&search_keyword=" +
                this.search_keyword;
            certificates = await axios.get(`course_candidates?${query}`);
            this.certificates = certificates.data.data;
            this.count = certificates.data.count;
            console.log(this.count);
            this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
            this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
            this.isLoading = false;
        },

        async Delete_this(id) {
            let check = confirm("Are you sure you want to delete the data");
            if (check) {
                try {
                    this.isLoading = true;
                    await axios.delete(`certificates/${id}`);
                    this.isLoading = false;
                    this.getData();
                } catch (e) {
                    this.isLoading = false;
                }
            }
        },

    },
};
</script>